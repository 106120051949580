import React from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import ContactForm from "../components/contact";

export default function Contact() {
  var link = document.createElement("link");

  // Définissez les attributs de la balise <link>
  link.rel = "canonical";
  link.href = "https://espacedetentereims.fr/contact"; // Remplacez par votre URL canonique réelle

  // Ajoutez la balise <link> au <head> de la page
  document.head.appendChild(link);
  return (
    <div className="mainContact">
      <Header />
      <div className="container__form">
        <ContactForm />
      </div>
      <Footer />
    </div>
  );
}
