import React from "react";
import Footer from "../components/footer";
import Header from "../components/header";
import { ArticleTextComplet } from "../components/article";

export default function Wellfare() {
  var link = document.createElement("link");

  // Définissez les attributs de la balise <link>
  link.rel = "canonical";
  link.href = "https://espacedetentereims.fr/bienetre"; // Remplacez par votre URL canonique réelle

  // Ajoutez la balise <link> au <head> de la page
  document.head.appendChild(link);
  return (
    <div className="mainHome">
      <Header />
      <ArticleTextComplet />
      <Footer />
    </div>
  );
}
