export default function Description() {
  return (
    <div className="container__description">
      <h1>Espace Détente, le spécialiste du Spa</h1>
      <p>
        ESPACE DETENTE, le seul magasin spécialiste de vente de{" "}
        <strong>SPA</strong> bien-être dans votre région. Une gamme complète de
        spa, selons vos besoins, vos envies. Du spa rond semi rigide, au spa
        bois ou encore carré. Espace détente vous propose une gamme complète
        pour vous détendre. Des marques de référence dans le domaine du bien
        être afin de vous garantir des produits de qualité et durable dans le
        temps. Nous vous accueillons dans notre magasin de Cormontreuil afin de
        vous faire découvrir notre gamme complète.
      </p>
    </div>
  );
}
